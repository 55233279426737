import React from 'react';
import { match, withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import { HashLink as Link } from 'react-router-hash-link';
import fb from '../../assets/icons/fb.svg';
import tw from '../../assets/icons/tw.svg';
import ins from '../../assets/icons/ins.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import SocialIcon from '../socialIcon';
import logo from '../../assets/footer/ipg_logo_black.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear';
import ico_success from '../../assets/icons/ico_success.svg';
import ico_error from '../../assets/icons/ico_error.svg';

import md5 from 'md5';
import ipgSDK from '../../ipg-sdk/api';

const style = (theme) => ({
    root: {
        backgroundColor: Color.black,
        [theme.breakpoints.up('mob')]: {
            padding: '80px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '80px 8px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '80px 96px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '80px 136px',
        },
        [theme.breakpoints.up('widedesktop')]: {
            padding: '80px 216px',
        },
        color: Color.lightGrey
    },
    subtitle: {
        color: Color.grey,
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 'bold',
        letterSpacing: '0.02em',
        padding: '32px 24px 12px'
    },
    address: {
        color: Color.white,
        padding: '16px 24px 0px',
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '0.04em',
    },
    column1: {
        // [theme.breakpoints.up('mob')]: {
        //     width: '100%',
        // },
        [theme.breakpoints.up('desktop')]: {
            width: '712px',
            marginRight: 56,
        },
        [theme.breakpoints.up('largedesktop')]: {
            marginRight: 16,
        },
        [theme.breakpoints.up('widedesktop')]: {
            marginRight: 56,
        },
    },
    column2: {
        // [theme.breakpoints.up('mob')]: {
        //     width: '100%',
        // },
        [theme.breakpoints.up('tabletv')]: {
            minWidth: '440px'
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minHeight: 156,
    },
    intellipro: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '0px 24px 20px'
    },
    rights: {
        color: Color.grey,
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 400,
        letterSpacing: '0.04em'
    },
    socialicons: {
        display: 'flex',
        flexDirection: 'row'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    block: {
        width: 120,
        height: 32,
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '0.04em',
        color: Color.lightGrey,
        padding: '4px 24px'
    },
    clickableBlock: {
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '0.04em',
        color: Color.lightGrey,
        padding: '4px 24px',
        width: 120,
        height: 32,
        textDecoration: 'underline',
        '&:hover': {
          cursor: 'pointer',
          color: Color.grey,
        },
        '&:active': {
          color: Color.lightGrey,
        },
      },
    blockLink: {
        width: 120,
        height: 32,
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '0.04em',
        color: Color.lightGrey,
        padding: '4px 24px',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            color: Color.grey
        },
        '&:active': {
            color: Color.lightGrey
        },
        '&:disabled': {
            color: Color.midGrey
        },
    },
    clearposition: {
        position: 'absolute',
        right: '20px',
        top: '30px',
        fontSize: '40px',
        cursor: 'pointer'

    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            marginTop: 16
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 20
        }
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '12px 0px'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 'calc(50% - 20px)',
            padding: '15px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 30px)'
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
            },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    messageField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingTop: 12,
            paddingBottom: 24,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 15,
            paddingBottom: 30,
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
    },
    messageBox: {
        [theme.breakpoints.up('mob')]: {
            height: 70
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 100
        },
        borderRadius: 0,
        border: '2px solid #E8E8E8',
        marginTop: 24,
        padding: '20px 24px',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
            border: '2px solid #377DED'
        },
        '&:focus': {
            border: '2px solid #377DED',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
        },
        '&:active': {
            border: '2px solid #377DED',
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        marginRight: 40,
        marginBottom: 16,
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    },
    dialogPaper: {
        '& .MuiPaper-root': {
            padding: '10px 50px'
        }
    },
    contact: {
        display: 'flex',
        paddingLeft: 24,
        flexWrap: 'wrap',
        fontSize: 20
    },
    mail: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '0.04em',
    },
})

const locationList = ['Silicon Valley', 'Los Angeles', 'New York', 'Seattle', 'Dallas', 'Vancouver', 'Toronto', 'Montreal', 'London', 'Amsterdam', 'Munich', 'Krakow', 'Dublin', 'Beijing', 'Shanghai', 'Hangzhou', 'Shenzhen', 'Wuhan', 'Manila', 'Singapore City', 'Chennai','Mexico City', 'Tokyo'];

class Footer extends React.Component {
    constructor() {
        super()
        this.state = {
            dialog: false,
            reporterName: "",
            bugTitle: "",
            reporterEmail: "",
            bugdescription: "",
            stepsToReproduce: "",
            bugimpact: "",
            sendStatus: 'Send',
            err: "",
            errorMessage: "The report haven’t been sent yet. Please try again.",
            loading: false
        }
    }
    componentDidMount() {
        this._handleOpenDialog()
    }

    componentDidUpdate() {
        this._handleOpenDialog()
    }

    _handleOpenDialog = () => {
        if (this.props.location.search.match(/\?openBountyProgram=/)) {
            const openBountyProgram = new URLSearchParams(this.props.location.search).get(
                'openBountyProgram'
            );
            // console.log('openBountyProgram',openBountyProgram)
            if (openBountyProgram === 'true' && !this.state.dialog) {
                this.openBugDialog()
            }
        }
    }

    openBugDialog = () => {
        this.setState({
            dialog: true,
            sendStatus: 'Send'
        })
    }

    handleClose = () => {
        this.props.history.replace('?')
        this.setState({
            dialog: false,
            err: "",
            reporterName: "",
            bugTitle: "",
            reporterEmail: "",
            bugdescription: "",
            stepsToReproduce: "",
            bugimpact: "",
            loading: false
        })
    }

    handleValueChange = (e, key) => {
        this.setState({
            [key]: e.target.value
        }, () => {
            this.setState({
                err: "",
                errorMessage: "The report haven’t been sent yet. Please try again."
            })
        })
    }

    sumbit = () => {
        if (this.state.sendStatus === 'Send') {
            let err = ";", valid = true;
            if (this.state.reporterName.trim() === "") {
                err += "Reporter Name required; ";
                valid = false;
            }
            if (this.state.bugTitle.trim() === "") {
                err += "Bug Title required; ";
                valid = false;
            }
            if (this.state.bugdescription.trim() === "") {
                valid = false;
                err += "Bug description required; ";
            }
            if (this.state.reporterEmail.trim() === "") {
                err += "Reporter Email required; ";
                valid = false;
            }
            else {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(this.state.reporterEmail.trim())) {
                    err += "Invalid email;\n";
                    valid = false;
                }
            }
            let time = Date.now();
            let checkSum = md5(`thisissalt${time}`);
            let obj = {
                name: this.state.reporterName,
                email: this.state.reporterEmail.trim(),
                title: this.state.bugTitle.trim(),
                description: this.state.bugdescription,
                reproduce: this.state.stepsToReproduce,
                impact: this.state.bugimpact,
                timestamp: time,
                checkSum
            }
            if (valid) {
                this.setState({
                    loading: true
                })
                ipgSDK.bugBounty(obj)
                    .then(response => {
                        if (response.id) {
                            this.setState({
                                sendStatus: 'ok',
                                loading: false
                            })
                        }
                    }).catch((err) => {
                        this.setState({
                            errorMessage: err.title,
                            sendStatus: 'failed',
                            loading: false
                        })
                    })
            } else {
                this.setState({ err: err.substring(1) }, () => { console.log(this.state) });
            }
        } else if (this.state.sendStatus === 'ok') {
            this.handleClose();
        } else {
            this.setState({
                sendStatus: 'Send',
                loading: false
            })
        }
    }

    handleCityClick = () => {
        window.location.href = 'https://www.intelliprogroup.jp/';
    };

    render() {
        const { classes, menuClicked, active } = this.props;
        const { sendStatus, loading } = this.state
        return (
            <footer className={classes.root}>
                <p className={classes.subtitle} role="heading" aria-level="3">Global Headquarters</p>
                <p className={classes.address}>160 E Tasman Drive, Suite 200, San Jose, CA 95134
                </p>
                <div className={classes.contact}>
                    <p className={`normal`} style={{ color: Color.white }}>Job Applications：</p>
                    <a className={classes.mail} style={{ color: Color.white, textDecoration: 'underline' }} href="mailto:hires@intelliprogroup.com">hires@intelliprogroup.com</a>
                    <p className={`normal`} style={{ color: Color.white }}>&nbsp;|&nbsp;</p>
                    <p className={`normal`} style={{ color: Color.white }}>Press Inquiries：</p>
                    <a className={classes.mail} style={{ color: Color.white, textDecoration: 'underline' }} href="mailto:pr@intelliprogroup.com">pr@intelliprogroup.com</a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div className={classes.column1}>
                        <p className={classes.subtitle} role="heading" aria-level="3">Global offices</p>
                        {/* <div className={classes.row} role="list">
                            {
                                locationList.map((item, index) => (
                                    <p className={classes.block} key={index} style={{ whiteSpace: 'nowrap' }} role="heading" aria-level="3">
                                        {item}
                                    </p>
                                ))
                            }
                        </div> */}
                         <div className={classes.row} role="list">
                            {locationList.slice(0, -1).map((item, index) => (
                            <p className={classes.block} key={index} style={{ whiteSpace: 'nowrap' }} role="heading" aria-level="3">
                                {item}
                            </p>
                            ))}
                            {locationList.slice(-1).map((item, index) => (
                            <p
                                className={classes.clickableBlock}
                                key={index + locationList.length - 2}
                                style={{ whiteSpace: 'nowrap' }}
                                role="heading"
                                aria-level="3"
                                onClick={this.handleCityClick}
                            >
                                {item}
                            </p>
                            ))}
                        </div>
                    </div>

                    <div className={classes.column2}>
                        <div style={{ paddingRight: '0 56px', display: 'flex', flexDirection: 'column' }}>
                            <p className={classes.subtitle}>About</p>
                            <Link to="/aboutus" style={{ textDecoration: 'none' }}>
                                <p className={classes.blockLink} onClick={() => menuClicked(4)}>
                                    About
                                </p>
                            </Link>
                            <Link to="/join" className={classes.blockLink}>
                                Careers
                            </Link>
                            <Link to="/contactus" style={{ textDecoration: 'none' }}>
                                <p className={classes.blockLink} onClick={() => menuClicked(5)}>
                                    Contact
                                </p>
                            </Link>
                            <Link to={{ pathname: '/', search: '?openBountyProgram=true' }} style={{ textDecoration: 'none' }}>
                                <p className={classes.blockLink} style={{ width: '210px' }} onClick={() => this.openBugDialog()}>
                                    Bug Bounty Program
                                </p>
                            </Link>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className={classes.subtitle}>Services</p>
                            <Link to="/recruiting" style={{ textDecoration: 'none' }}>
                                <p className={classes.blockLink} style={{ width: 240 }} onClick={() => menuClicked(1)}>Recruiting & Staffing</p>
                            </Link>
                            <Link to="/service" style={{ textDecoration: 'none' }}>
                                <p className={classes.blockLink} style={{ width: 240 }} onClick={() => menuClicked(2)}>Managed Services</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 56 }}>
                    <div className={classes.intellipro}>
                        <img src={logo} style={{ width: 96, height: 24, paddingRight: 16 }} alt="intelliprogroup logo"></img>
                        <p className={classes.rights} role="heading" aria-level="3">
                            &copy; {new Date().getFullYear()} IntelliPro. All Rights Reserved
                        </p>
                        <p className={classes.rights}>&nbsp;|&nbsp;</p>
                        <Link className={classes.rights} style={{ color: Color.grey }} to="/terms" >Terms of Use</Link>
                        {/* <p className={classes.rights}>&nbsp;|&nbsp;</p> */}
                        {/* <Link className={classes.rights} style={{ color: Color.grey }} to="/notice" >Government Notice</Link> */}
                    </div>
                    <div className={classes.socialicons}>
                        <SocialIcon img={fb} link={'https://www.facebook.com/IntelliProTalent/'} />
                        <SocialIcon img={tw} link={'https://twitter.com/intelli_pro'} />
                        <SocialIcon img={ins} link={'https://instagram.com/intellipro?utm_medium=copy_link'} />
                        <SocialIcon img={linkedin} link={'https://www.linkedin.com/company/intellipro-group-inc/?viewAsMember=true'} />
                    </div>
                </div>
                <Dialog
                    open={this.state.dialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ maxWidth: '960px', margin: '0 auto' }}
                    className={classes.dialogPaper}
                >
                    {sendStatus === 'Send' && <>
                        <DialogTitle>
                            <div className='h1' role="heading" aria-level="1">Report A</div>
                        </DialogTitle>
                        <DialogTitle style={{ padding: '0px 24px' }}>
                            <div className='h1' role="heading" aria-level="1">Vulnerability</div>
                        </DialogTitle>
                    </>
                    }
                    {sendStatus === 'ok' && <>
                        <DialogTitle>
                            <div className='h1' role="heading" aria-level="1">Report sent!</div>
                        </DialogTitle>
                    </>
                    }
                    {sendStatus === 'failed' && <>
                        <DialogTitle>
                            <div className='h1' role="heading" aria-level="1">Sorry, something went wrong.</div>
                        </DialogTitle>
                    </>
                    }
                    {sendStatus === 'Send' && <ClearIcon className={classes.clearposition} onClick={this.handleClose} />}
                    {sendStatus === 'Send' && <DialogContentText style={{ maxWidth: '960px', height: '200px', padding: '20px 20px 10px 20px' }}>
                        If you believe you have discovered a security or privacy vulnerability that affects Intelliprogroup software, services, or web servers, please report it to us. We welcome reports from everyone, including security researchers, developers, and customers. Please allow us up to 7 days to respond before sending another email on the matter.
                    </DialogContentText>}
                    {
                        sendStatus === 'ok' && <DialogContentText style={{ maxWidth: '960px', padding: '10px 20px' }}>The report has been sent. Thanks for helping keep IntellPro safe.</DialogContentText>
                    }
                    {
                        sendStatus === 'failed' && <DialogContentText style={{ maxWidth: '960px', padding: '10px 20px' }}>{this.state.errorMessage}
                            {/* The report haven’t been sent yet. Please try again. */}
                        </DialogContentText>
                    }
                    {sendStatus === 'Send' && (
                        <DialogContent>
                            <div className={classes.form}>
                                <div className={classes.formInputField}>
                                    <label htmlFor="ReporterName">
                                        <span>Reporter Name </span>
                                        <span style={{ color: Color.intelligentBlue }}>*</span>
                                    </label>
                                    <input type="text" required placeholder="Reporter Name" id="ReporterName" title="Please fill in this field" name="ReporterName" maxLength={255} value={this.state.reporterName} onChange={e => this.handleValueChange(e, 'reporterName')}></input>
                                </div>

                                <div className={classes.formInputField}>
                                    <label htmlFor="ReporterEmail">
                                        <span>Reporter Email </span>
                                        <span style={{ color: Color.intelligentBlue }}>*</span>
                                    </label>
                                    <input type="text" required placeholder="Reporter Email" id="ReporterEmail" title="Please fill in this field" name="ReporterEmail" maxLength={255} value={this.state.reporterEmail} onChange={e => this.handleValueChange(e, 'reporterEmail')}></input>
                                </div>

                                <div className={classes.formInputField} style={{ width: '100%' }}>
                                    <label htmlFor="BugTitle">
                                        <span>Bug Title </span>
                                        <span style={{ color: Color.intelligentBlue }}>*</span>
                                    </label>
                                    <input type="text" required placeholder="Bug Title" id="BugTitle" title="Please fill in this field" name="BugTitle" maxLength={255} value={this.state.bugTitle} onChange={e => this.handleValueChange(e, 'bugTitle')}></input>
                                </div>
                                <div className={classes.messageField}>
                                    <label htmlFor="Bugdescription">
                                        <span>Bug description</span>
                                        <span style={{ color: Color.intelligentBlue }}>*</span>
                                    </label>
                                    <textarea type="text" cols="20" rows="2" className={classes.messageBox} title="Please fill in this field" required placeholder="Bug description" name="Bugdescription" maxLength={2000} value={this.state.bugdescription} onChange={e => this.handleValueChange(e, 'bugdescription')}></textarea>
                                </div>
                                <div className={classes.messageField}>
                                    <label htmlFor="StepsToReproduce">
                                        <span>Steps to reproduce</span>
                                    </label>
                                    <textarea type="text" cols="20" rows="2" className={classes.messageBox} required placeholder="Steps to reproduce" name="StepsToReproduce" maxLength={2000} value={this.state.stepsToReproduce} onChange={e => this.handleValueChange(e, 'stepsToReproduce')}></textarea>
                                </div>
                                <div className={classes.messageField}>
                                    <label htmlFor="Bugimpact">
                                        <span>Bug impact</span>
                                    </label>
                                    <textarea type="text" cols="20" rows="2" className={classes.messageBox} required placeholder="Bug impact" name="Bugimpact" maxLength={2000} value={this.state.bugimpact} onChange={e => this.handleValueChange(e, 'bugimpact')}></textarea>
                                </div>
                            </div>
                        </DialogContent>
                    )}
                    <DialogActions style={{ justifyContent: 'flex-start', paddingLeft: '25px' }}>
                        <button className={classes.button} type="submit" disabled={loading} onClick={() => this.sumbit()}>{sendStatus === 'Send' && !loading ? 'Send' : sendStatus === 'ok' && !loading ? 'Okay' : sendStatus === 'failed' && !loading ? 'Retry' : 'Loading...'}</button>
                    </DialogActions>
                    <p className="normal" style={{ alignItems: 'flex-start' }}>
                        <img
                            style={this.state.err !== "" ? { display: 'flex', width: 32, paddingRight: 8 } : { display: "none" }}
                            src={this.state.err !== "" ? ico_error : ""}
                            alt="icon"
                        ></img>
                        {this.state.err}
                    </p>
                </Dialog>
            </footer>
        )
    }
}

export default withRouter(withStyles(style)(Footer))