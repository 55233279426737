import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import SqrBtnMain from '../../components/squareBtnMain';
import PlaceCard from '../../components/placeCard';
import ContactForm from '../../components/contactForm';
import HiringForm from '../../components/hiringForm';
import switchForm from '../../components/switchForm';

import ico_down from '../../assets/icons/ico_arrow_down.svg';
import ContactUsImg from '../../assets/contactus/cotactus.jpeg';
import HeaderquarterImg from '../../assets/contactus/HeadQuater.jpeg';
import worldMap from '../../assets/contactus/worldMap.jpg';
import blueMapPin from '../../assets/icons/ico_blue_map_pin.svg';
import mapPin from '../../assets/icons/ico_map_pin.svg';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import SwitchForm from '../../components/switchForm';

const style = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.up('mob')]: {
      height: 518,
    },
    [theme.breakpoints.up('tabletv')]: {
      height: 480,
    },
    [theme.breakpoints.up('tableth')]: {
      height: 640,
    },
    [theme.breakpoints.up('desktop')]: {
      height: 800,
    },
    [theme.breakpoints.up('largedesktop')]: {},
  },
  headerImg: {
    objectFit: 'cover',
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      height: 288,
    },
    [theme.breakpoints.up('tabletv')]: {
      width: '50%',
      height: 480,
    },
    [theme.breakpoints.up('tableth')]: {
      height: 640,
    },
    [theme.breakpoints.up('desktop')]: {
      height: 800,
    },
  },
  headerTitle: {
    position: 'relative',
    [theme.breakpoints.up('mob')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tabletv')]: {
      width: '50%',
      height: 480,
    },
    [theme.breakpoints.up('tableth')]: {
      height: 640,
    },
    [theme.breakpoints.up('desktop')]: {
      height: 800,
    },
  },
  title: {
    [theme.breakpoints.up('mob')]: {
      padding: '60px 24px',
      width: 240,
    },
    [theme.breakpoints.up('tabletv')]: {
      padding: '176px 32px 0px',
      width: 288,
    },
    [theme.breakpoints.up('tableth')]: {
      padding: '256px 32px 0px',
      width: 360,
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '208px 120px 0px',
      width: '80%',
      maxWidth: 480,
    },
    [theme.breakpoints.up('largedesktop')]: {
      padding: '240px 240px 0px',
    },
  },
  button: {
    [theme.breakpoints.up('mob')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  serviceTitle: {
    width: '100%',
  },
  servicePara: {
    [theme.breakpoints.up('mob')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  main: {
    [theme.breakpoints.up('mob')]: {
      padding: '0px 24px',
    },
    [theme.breakpoints.up('tabletv')]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.up('tableth')]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '0px 120px',
    },
    [theme.breakpoints.up('largedesktop')]: {
      padding: '0px 240px',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardBlock: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('mob')]: {
      paddingTop: 40,
    },
    [theme.breakpoints.up('tableth')]: {
      paddingTop: 60
    },
    [theme.breakpoints.up('desktop')]: {
      paddingTop: 60,
      paddingBottom: 20,
    },
  },
  headquarter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.up('mob')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
    [theme.breakpoints.up('tableth')]: {
      paddingTop: 60,
      paddingBottom: 10,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingTop: 80,
      paddingBottom: 10,
    },
  },

  headquarterImg: {
    [theme.breakpoints.up('mob')]: {
      maxWidth: 366,
      width: '100%',
      // height: 183,
      paddingRight: 32,
    },
    [theme.breakpoints.up('tabletv')]: {
      maxWidth: 560,
      // height: 280
    },

    [theme.breakpoints.up('desktop')]: {
      width: 520,
      maxWidth: 'unset',
      height: 400,
    },
    [theme.breakpoints.up('largedesktop')]: {
      paddingRight: 40,
    },
  },
  santaClara: {
    [theme.breakpoints.up('mob')]: {
      paddingBottom: 24,
      paddingTop: 32,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: 32,
    },
  },
  address: {
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      paddingBottom: 26,
    },
    [theme.breakpoints.up('tableth')]: {
      width: 420,
    },
    [theme.breakpoints.up('desktop')]: {
      width: 420,
    },
  },
  contact: {
    display: 'flex',
    paddingBottom: 24,
    flexWrap: 'wrap',
  },
  mail: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '32px',
    letterSpacing: '0.04em',
  },
  officeTitle: {
    [theme.breakpoints.up('mob')]: {
      paddingBottom: 40,
    },
    [theme.breakpoints.up('tableth')]: {
      paddingBottom: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: 80,
    },
  },
  usOffice: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('mob')]: {
      margin: '-12px -24px',
    },
    [theme.breakpoints.up('tableth')]: {
      margin: '-18px -36px',
    },
    [theme.breakpoints.up('desktop')]: {
      margin: '-20px -40px',
    },
  },
  bottomImage: {
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      marginTop: '20px',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '100%',
      marginTop: '50px',
    },
  },
});

class Contact extends React.Component {
  constructor() {
    super();
  }
  render() {
    const { classes, menuClicked } = this.props;

    return (
      <div>
        <Helmet>
          <title>Let's Connect!</title>
          <meta property="og:title" content="Santa Clara, California global headquarters. Multiple U.S. and international offices" />
          <meta property="og:description" content="Santa Clara, California global headquarters. Multiple U.S. and international offices. " />
          <meta name="description" content="Santa Clara, California global headquarters. Multiple U.S. and international offices. " />
        </Helmet>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <title className={classes.title}>Let's Connect</title>
            <div className={classes.button}>
              <a href="/contactus#form">
                <SqrBtnMain icons={ico_down} content={'Contact Us'} />
              </a>
            </div>
          </div>
          <img src={ContactUsImg} alt="contact us" className={classes.headerImg}></img>
        </div>
        {/* <HiringForm title={'Get In Touch'} id='form'/> */}
        {/* <SwitchForm title1={'Employer'} title2={'Job Seeker'} id="form" /> */}

        <div className={classes.main}>
          <div className={`${classes.block} ${classes.cardBlock}`}>
            <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
            Global Headquarters
            </div>
            <div className={classes.headquarter}>
              <img src={HeaderquarterImg} alt="contact us" className={classes.headquarterImg}></img>
              <div className={classes.address}>
                <h2 className={`hro ${classes.santaClara}`}>San Jose</h2>
                <p className={`normal`} style={{ color: Color.midGrey }}>
                160 E Tasman Drive, Suite 200, San Jose, CA 95134
                </p>
                {/* <p className={`normal`} style={{ color: Color.midGrey }}>
                  Otemachi, Chiyoda-ku Tokyo 100-0004 &nbsp;
                </p> */}
                <p className={`normal`} style={{ color: Color.midGrey }}>
                  Employers: &nbsp;{' '}
                  <a
                    className={classes.mail}
                    style={{
                      color: Color.midGrey,
                      textDecoration: 'underline',
                    }}
                    href="mailto:hires@intelliprogroup.com"
                  >
                    hires@intelliprogroup.com{' '}
                  </a>
                </p>
                <p className={`normal`} style={{ color: Color.midGrey }}>
                  Job Seekers: &nbsp;{' '}
                  <a
                    className={classes.mail}
                    style={{
                      color: Color.midGrey,
                      textDecoration: 'underline',
                    }}
                    href="mailto:jobs@intelliprogroup.com"
                  >
                    jobs@intelliprogroup.com{' '}
                  </a>
                </p>
                <p className={`normal`} style={{ color: Color.midGrey }}>
                  Press Inquiries: &nbsp;
                  <a
                    className={classes.mail}
                    style={{
                      color: Color.midGrey,
                      textDecoration: 'underline',
                    }}
                    href="mailto:pr@intelliprogroup.com"
                  >
                    pr@intelliprogroup.com
                  </a>
                </p>
                <p className={`normal`} style={{ color: Color.midGrey }}>
                  Contact Number: 408.200.9891{' '}
                </p>

                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <img src={blueMapPin} alt="map pin icon" style={{ width: 24, height: 24 }}></img>
                  <a
                    href="https://maps.app.goo.gl/Q4wx9reK6xLEaffeA"
                    style={{
                      color: Color.intelligentBlue,
                      fontSize: 15,
                      lineHeight: '24px',
                      fontWeight: 700,
                      letterSpacing: '0.02em',
                    }}
                  >
                    Find us on Map
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${classes.block} ${classes.cardBlock}`}
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
            }}
          >
            <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
              Service Coverage
            </div>
            <div className={classes.headquarter}>
              <img src={worldMap} alt="contact us" className={classes.bottomImage}></img>
            </div>
          </div>

          
          {/* <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.officeTitle}`} role="heading" aria-level="1">
                            U.S. Offices
                        </div>
                        <div className={classes.usOffice}>
                            <PlaceCard place={'Silicon Valley, CA'}/>
                            <PlaceCard place={'Los Angeles, CA'}/>
                            <PlaceCard place={'New York, NY'}/>
                            <PlaceCard place={'Seattle, WA'}/>
                            <PlaceCard place={'Dallas, TX'}/>
                        </div>
                    </div> */}
          {/* <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.officeTitle}`} role="heading" aria-level="1">International Offices</div>
                        <div className={classes.usOffice}>
                            <PlaceCard place={'London'} country='United Kingdom'/>
                            <PlaceCard place={'Munich'} country='Germany' />
                            <PlaceCard place={'Amsterdam'} country='The Netherlands'/>
                            <PlaceCard place={'Krakow'} country='Poland' />
                            <PlaceCard place={'Dublin'} country='Ireland' />
                            <PlaceCard place={'Shanghai'} country='China'/>
                            <PlaceCard place={'Beijing'} country='China'/>
                            <PlaceCard place={'Hangzhou'} country='China'/>
                            <PlaceCard place={'Shenzhen'} country='China'/>
                            <PlaceCard place={'Wuhan'} country='China'/>
                            <PlaceCard place={'Singapore'} country='Singapore'/>
                            <PlaceCard place={'Manila'} country='Phillippines'/>
                            <PlaceCard place={'Chennai'} country='India'/>
                            <PlaceCard place={'Toronto'} country='Canada'/>
                            <PlaceCard place={'Vancouver'} country='Canada'/>
                            <PlaceCard place={'Montreal'} country='Canada'/>
                            <PlaceCard place={'Mexico City'} country='Mexico'/>
                        </div>
                    </div> */}
        </div>
        <SwitchForm title1={'Employer'} title2={'Job Seeker'} id="form" />
      </div>
    );
  }
}

export default withStyles(style)(Contact);
