import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Color } from '../../assets/color';

import BenefitCard from '../../components/benefitCard';

import Benefit1 from '../../assets/benefit/benefit1_2.png';
import Benefit2 from '../../assets/benefit/benefit2.jpeg';
import Benefit3 from '../../assets/benefit/benefit3.jpeg';
import Benefit4 from '../../assets/benefit/benefit4.jpeg';
import Benefit5 from '../../assets/benefit/benefit5.png';
import Benefit6 from '../../assets/benefit/benefit6.jpeg';


const styles = theme => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 200,
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 288,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            height: 384,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            height: 480,
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: '200px',
        },
    },
    cardBlockBottom: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: '200px',
        },
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '100%',
            paddingBottom: 72,
            // maxWidth: 400
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    benefitGroup: {
        margin: '-24px',
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 200,
        },
    }
})

const content = [
    [
        {
            list:'Get a clear view on what each plan includes and how much they cost, click here:', 
            redirect:'IntelliPro 2024 Benefits Overview',
            link:'https://docs.google.com/presentation/d/14C9WJvYROdmXHxTyHAvRwHW2t_VYJ997_mZ5BBAfFsU/edit?usp=sharing'
        }
    ],
    [
        {
            list:'To view more information about the medical plans (HDHP-HSA plan, OAP low plan, OAP high plan, OAP No Duductible, HMO-for CA employees only, Kaiser- for CA employees only), click here:',
            redirect:'Medical plans',
            link:'https://drive.google.com/drive/folders/1ZNBUx5LbXPAfAW5PA2pS2DdEqQ7UUrzU'
        },
        {
            list:'To view more information about the dental plans (DPPO Base plan，OPPO Buy up plan), click here: ',
            redirect:'Dental Plans',
            link:'https://drive.google.com/drive/folders/1bbDvTKGbhlwT0ggfz0KU-UHWgH03km5p?usp=sharing'
        },
        {
            list:'To view more information about the vision plan, click here: ', 
            redirect:'Vision Plan',
            link: 'https://drive.google.com/drive/folders/1VALltG4CBP_kx7U0WAXXL19RfdwjCqJt?usp=sharing'
        },
        {
            list:'To learn about step-by-step online insurance enrollment instruction, click here: ', 
            redirect:'ADP Online Insurance Enrollment Instruction',
            link:'https://docs.google.com/document/d/1TrY63jWtXA4fo5p0DT11kI7zHGyr6EAj/edit?usp=sharing&ouid=101021535455504398209&rtpof=true&sd=true'
        }
    ],
    [
        {
            list:'To view more information about the transit program and the parking program, click here: ',
            redirect:'Transit and Parking Program',
            link:'https://drive.google.com/drive/folders/1mkIPEXOB-MSuXx-ed_u2aqwx33zlXolG?usp=sharing'
        
        },
        {
            list:'To learn about the registration process, click here: ',
            redirect:'Participant Registration Process',
            link:'https://docs.google.com/presentation/d/1GQ8am2SocJZu1_Kf_typhbUBXE9iFTYF/edit?usp=sharing&ouid=101021535455504398209&rtpof=true&sd=true'
        
        }
    ],
    [
        {
            list:'To view more information about the 401K plan, click here:',
            redirect:'401K_En',
            link:'https://drive.google.com/file/d/1UR3U3SJmjcGVJ7bcgFuLpwufcgxEG_7M/view?usp=sharing'
        
        }
    ],
    [
        {
            list:'To learn about the Paid Sick Leave Policy, click here: ', 
            redirect:'Paid Sick Leave Policy',
            link:'/file/2025IntelliproSickLeavePolicy.pdf'
        }
    ],
    [
        {
            list:'To learn about the California Notices, click here: ',
            redirect:'CA Notices',
            link:'https://drive.google.com/file/d/1pS4u224auHbvZm2kJOi3ye_tr7xK0MVu/view?usp=sharing'
        }
    ],
]

class Benefits extends React.Component {

    constructor() {
        super()
    }

    render() {

        const{classes} = this.props;

        return(
            <div>
                <div className={classes.header}>
                        <div className={`h1`} style={{color: Color.white}} role="heading" aria-level="1">
                            Employee Benefits
                        </div>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
                            Overview
                        </div>
                        <p className={`normal ${classes.servicePara}`}>As a valuable employee with IntelliPro, you are eligible for the following benefit options, including insurance, 401K, commuter benefits, paid sick leave, family & medical leave, COBRA. You will find out more details on the IPG employee benefits by exploring this page.</p>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">Benefits</div>
                    </div>
                    <div className={classes.benefitGroup}>
                        <BenefitCard img={Benefit1} title={'General Benefits Overview'} para={'IPG offers four medical plans (HDHP-HSA plan, OAP low plan, OAP high plan, OAP No Duductible, HMO-for CA employees only, Kaiser- for CA employees only), two dental plans (DPPO Base plan，OPPO Buy up plan) and one vision plan.'} content={content[0]}/>
                        <BenefitCard img={Benefit2} title={'Insurance'} para={'Here, you will find all the detailed information about the medical plans, dental plans and vision plan IPG provides. '} content={content[1]}/>
                        <BenefitCard img={Benefit3} title={'Commuter Benefit Program'} para={'Here you will find all the detailed information about the commuter benefit program IPG offers and the steps to register for it.'} content={content[2]}/>
                        <BenefitCard img={Benefit4} title={'401K'} para={'Here you will find all the detailed information about the 401K plan IPG offers.'} content={content[3]}/>
                        <BenefitCard img={Benefit5} title={'Paid Sick Leave'} para={'Here you will find all the detailed information about the IPG’s Paid Sick Leave policy.'} content={content[4]}/>
                        <BenefitCard img={Benefit6} title={'California Notices'} para={'Here you will find employee rights and benefits issued by the California government (only relevant to CA employees).'} content={content[5]}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Benefits);

                        